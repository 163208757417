import './App.css';
import  Navbar  from './Components/Navbar';
import Textform from './Components/Testform';
import Aleart from './Components/Aleart';
import React, {useState} from 'react';


function App() {
  const [mode, setMode] = useState('light');
  const [alert, setAleart] = useState(null);
  const showAleart = (message, type) => {
    setAleart({
      msg: message,
      type: type
    })
  }
  setTimeout(() => {
    setAleart(null);
  }, 2500);
  const toggleMode = () => {
    if (mode === 'light') {
      setMode('dark');
      document.body.style.backgroundColor = 'black';
      showAleart('Darkmode enable', 'success');
    } else {
      setMode('light');
      document.body.style.backgroundColor = 'white';
      showAleart('Lightmode enable', 'success')
    }
  }
 
  return (
    <>
      <Navbar title="Amit React" mode={mode} toggleMode={toggleMode}/>
      {/* <Navbar/> */}
      <Aleart alert={alert}/>
      <div className='container'>
      <Textform showAleart={showAleart} heading="Enter the text to analyze." mode={mode}/>
      </div>
      
    </>
  );
}

export default App;

import React, {useState} from 'react';

export default function Textform(props) {
    const clickUpper = () => {
        console.log("Uppercase Active");
        let newtext = text.toUpperCase();
        setText(newtext);
    }

    const clickLower = () => {
        console.log("Lowercase Active");
        let newtext = text.toLowerCase();
        setText(newtext);
    }

    const clickCopy = () => {
        let copytext = document.getElementById('myBox');
        copytext.select();
        navigator.clipboard.writeText(copytext.value);
        props.showAleart('Text Copied', 'success');
    }

    const clickclear = () =>{
        let clear = "";
        setText(clear);

    }

    const clickOn = (event) => {
        console.log("On Change Active");
        setText(event.target.value);
    }

    const [text, setText] = useState('Enter the text here.');
    return(
        <>
        <div className="mb-3" style={{color: props.mode === 'dark' ? 'white':'black'}}>
            <div className="mb-3">
            <h1>{props.heading}</h1>
            <label className="form-label">Example textarea</label>
            <textarea className="form-control" value={text} onChange={clickOn} id="myBox" style={{backgroundColor: props.mode === 'dark' ? 'gray':'light', color: props.mode === 'dark' ? 'white':'black'}} rows="8"></textarea>
            </div>
            <button className="btn btn-primary mx-2" onClick={clickUpper}>Convert to Uppercase</button>
            <button className="btn btn-secondary mx-2" onClick={clickLower}>Convert to Lowercase</button>
            <button className="btn btn-primary mx-2" onClick={clickCopy}>Copt Text</button>
            <button className="btn btn-danger mx-2" onClick={clickclear}>Clear</button>
        </div>
        <div className="mb-3" style={{color: props.mode === 'dark' ? 'white':'black'}}>
            <div className="mb-3">
            <h1>Your text Summary</h1>
                <p>{text.split(" ").length} words and {text.length} characters</p>
                <p>{0.008*text.split(" ").length}Minuites read</p>
                <h3>Preview</h3>
                <p>{text.length>0 ? text:'Enter something in the box above to preview here.'}</p>
            </div>
        </div>
        </>
    );
}
